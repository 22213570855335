import {useState} from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '../css/events.css';
import { FaLocationDot } from 'react-icons/fa6';
import Pagination from '@mui/material/Pagination';
import { EVENTS } from '../constants';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';


const IMAGES = [
    require('../assets/workshop-pictures/Camps/1EE5FBD7-7EBB-42C2-BD7A-0B749C9F336A.jpeg'),
    require('../assets/workshop-pictures/Camps/8DA901A6-B74E-4115-BDED-F593BDB1032B.jpeg'),
    require('../assets/workshop-pictures/Camps/8E6D7B63-408A-4EDD-8415-C0A9C8C58F15.jpeg'),
    require('../assets/workshop-pictures/Camps/AEF7E129-6F35-440F-B9AB-8CEEDA867A68.jpeg'),
    require('../assets/workshop-pictures/Camps/E8463E77-094B-40ED-BDA7-70D39E8DCD86.jpeg'),
]

const ImageCarousel = () => {
  return (
    <MDBCarousel showIndicators showControls fade>
        {IMAGES.map((img, key) => (
            <MDBCarouselItem
                key={key}
                className='w-100 d-block'
                itemId={key+1}
                src={img}
                alt='...'
            >
            </MDBCarouselItem>
        ))}
    </MDBCarousel>
  );
}

const Events = () => {
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 4;

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = EVENTS.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(EVENTS.length / itemsPerPage);

  const handlePageChange = (event, page) => {
    const newOffset = ((page - 1) * itemsPerPage) % EVENTS.length;
    setItemOffset(newOffset);
  };
  return (
    <div className='flex-col flex-center'>
      <h1 className='title'>Past Events </h1>
       <div className='max-width-1200'>
          <p className='text-justify banner-description'>
            On July 16th, 2023, P2P organized an engaging STEM & Business Camp catering to young participants aged 6-14.
              During this event, we introduced four major fields of study: engineering, medicine, computer science, and business, with hands-on activities designed to spark curiosity and learning.
          </p>
          <p className='text-justify banner-description'>
            In the engineering segment, students were challenged to construct the tallest tower using spaghetti and marshmallows, with an exciting prize awaiting the victor. 
          </p>
          <p className='text-justify banner-description'>
            The medicine workshop involved three captivating experiments: creating slime with laundry detergent and glue, understanding the importance of handwashing using water, pepper, and soap, and crafting color-changing flowers with flowers, water, and food coloring. 
          </p>
          <p className='text-justify banner-description'>
            In the computer science module, students delved into the fundamentals of coding and had hands-on experience with Arduino. 
          </p>
          <p className='text-justify banner-description'>
            Finally, in the business domain, students engaged in lively debates on popular topics like iPhones vs. Androids and Cats vs. Dogs. They were also tasked with developing a solution, product, and a business pitch to address a societal issue. 
          </p>
          <p className='text-justify banner-description'>
            To recognize their hard work and creative thinking, participants received certificates and prizes. Below, you'll find a gallery of pictures capturing the memorable moments of the camp. 
          </p>
        </div>
        <div className='flx-row flex-space-around max-width-1200'>
            <div className='flex-col text-align-start events'>
                {currentItems.map((e) => (
                    <div key={e.title} className='flx-row-fixed event'>
                        <div className='event-timeline'>
                            <div><strong>{e.date}</strong></div>
                            <div>{e.time}</div>
                        </div>
                        <div>
                            <div><strong>{e.title} </strong></div>
                            <div>
                                <FaLocationDot />
                                <span>{e.location || e.mode}</span>
                            </div>
                        </div>
                    </div>
                ))
                }
                <div className='flx-row flex-center pagination-container'>
                  <Pagination onChange={handlePageChange} count={pageCount} size="small" />
                </div>
            </div>
            <div className='flex-col carousel-container'>
              <ImageCarousel />
            </div>
        </div>
    </div>
  );
}

export default Events;
