import '../css/team.css';
import { TEAM } from "../constants";

const Team = () => {
  return (
    <div className='flex-col flex-center gradient-bg2'>
        <h1 className='title font-weight-300'>Meet the Team</h1>
        <div className='flx-row flex-center max-width-1200 container'>
            {TEAM.map(({name, image, title}) => (
              <div key={name} className="flex-col flex-center team-container">
                <img className="team-img" src={image} alt={name} />
                <div className="team flex-col">
                  <p className="team-text">
                      <strong>{name}<br /></strong>
                      {title}
                  </p>
                </div>
              </div>
                ))}
        </div>
    </div>
  );
}

export default Team;
