import '../css/footer.css';

const Footer = ({handleLinkClick, sections}) => {
    return (
        <footer className="footer flex-col flex-center">
            <div className="max-width-1200 flex-col flex-center width-100">
                <div className="title app-title">Project2Provide</div>
                <div className='flx-row-fixed flex-center nav-container'>
                    <a className='nav-links' onClick={(e) => handleLinkClick(e, sections[''], '')} href='/'>Home</a>
                    <a className='nav-links' onClick={(e) => handleLinkClick(e, sections['about-us'], 'about-us')} href='about-us'>About Us</a>
                    <a className='nav-links' onClick={(e) => handleLinkClick(e, sections['our-team'], 'our-team')} href='our-team'>Our Team</a>
                    <a className='nav-links' onClick={(e) => handleLinkClick(e, sections['events'], 'events')} href='events'>Events</a>
                    <a className='nav-links' onClick={(e) => handleLinkClick(e, sections['contact-us'], 'contact-us')} href='contact-us'>Contact Us</a>
                </div>
                <hr className="divider width-100"></hr>
                <div className="text-center copyright-text">© 2023 Project2Provide. All rights reserved.</div>
            </div>
        </footer>
    )
};

export default Footer;