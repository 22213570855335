import Image1 from '../assets/about.jpg';
import Image2 from '../assets/about1.jpg';
import Image3 from '../assets/about2.jpg';

const AboutUs = () => {
  return (
    <div className='flex-col flex-center'>
        <h1 className='title'>About Us</h1>
        <div className='flx-row flex-space-around scetion-container max-width-1200'>
            <div className='flex-col width-20'>
              <img className='hide-small' src={Image2} alt='banner'/>
              <img src={Image3} alt='banner'/>
              <img className='hide-small' src={Image1} alt='banner'/>
            </div>
            <div className='flex-col text-justify width-50'>
                <p className='banner-description'>Project2Provide (P2P) is a non-profit organization with a mission to help high school students navigate the often-challenging process of choosing a college major. Founded by Srinithi Arumugam during her junior year, P2P was born out of a recognition that many students lack exposure to diverse academic fields, hindering their ability to make informed decisions about their future careers. </p>
                <p className='banner-description'>Srinithi's personal journey into coding ignited a strong passion for engineering, motivating her to create a platform that would inspire younger students to explore and develop an interest in the academic majors that resonate with them. </p>
                <p className='banner-description'>At P2P, our primary focus is on STEM (Science, Technology, Engineering, and Mathematics) education. We are committed to sparking curiosity in these fields among younger generations while highlighting the significance of STEM disciplines in today's world. One of our core goals is to address and reduce the gender gap prevalent in STEM-related majors. </p>
                <p className='banner-description'>Through our non-profit, we host a range of workshops and day camps tailored for children aged 6 to 18. In our day camps, designed for younger kids, we employ interactive and enjoyable methods such as experiments, debates, and hands-on building activities to introduce them to STEM and business concepts. </p>
                <p className='banner-description'>For a glimpse into our past activities and events, we invite you to explore our "Past Events" section, where you can find photos and detailed descriptions of every event we've hosted. Join us on our journey to inspire the next generation of thinkers and innovators in the world of STEM. </p>
            </div>
        </div>
    </div>
  );
}

export default AboutUs;
