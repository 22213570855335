import banner from '../assets/banner.svg'

const Home = ({handleNavClick}) => {
  return (
    <div className='flx-row flex-center gradient-bg'>
      <div className='flx-row flex-space-around min-height-50 max-width-1200'>
        <div className='flex-col text-align-start width-50'>
            <h1 className='title'>Project2Provide</h1>
            <p className='banner-description text-justify'>At P2P, our primary focus is on STEM (Science, Technology, Engineering, and Mathematics) education. We are committed to sparking curiosity in these fields among younger generations while highlighting the significance of STEM disciplines in today's world. One of our core goals is to address and reduce the gender gap prevalent in STEM-related majors. </p>
            <a className='banner-link' href='/contact' onClick={(e) => handleNavClick(e, 'contact-us')}>Contact Us</a>
        </div>
        <img className='banner-image' src={banner} alt='banner'/>
      </div>
    </div>
  );
}

export default Home;
