const TEAM = [
    {
        name: "Srinithi Arumugam",
        title: 'CEO & Founder',
        image: require('./assets/srinithi.png')
    },
    {
        name: "Eesha Avula",
        title: 'Marketing Director',
        image: require('./assets/eesha.png')
    },
    {
        name: "Rishik Boddeti",
        title: 'Workshop Director',
        image: require('./assets/rishik.png')
    },
    {
        name: "Annetta Davoli",
        title: 'Workshop Director',
        image: require('./assets/annetta.png')
    },
    {
        name: "Shrivetha Senthil",
        title: 'Social Media Officer',
        image: require('./assets/shrivetha.png')
    },
    {
        name: "Floria Xu",
        title: 'Program Director',
        image: require('./assets/floria.png')
    }
];

const EVENTS = [
    {
        date: '20th Jul, 2023',
        time: '3-4PM',
        mode: 'Virtual',
        age: '12-18',
        title: 'You Can Code',
        location: '',
    },
    {
        date: '21st Jul, 2023',
        time: '3-4PM',
        mode: 'Virtual',
        age: '12-18',
        title: 'Mobile App Design',
        location: '',
    },
    {
        date: '24th Jul, 2023',
        time: '3-4PM',
        mode: 'Virtual',
        age: '12-18',
        title: 'Video Game Development using Scratch',
        location: '',
    },
    {
        date: '26th Jul, 2023',
        time: '3-4PM',
        mode: 'Virtual',
        age: '12-18',
        title: 'Website Development (Learn HTML & CSS)',
        location: '8637 Spicewood Springs Rd, Austin, TX 78759 ',
    },
    {
        date: '28th Jul, 2023',
        time: '3-4PM',
        mode: 'Virtual',
        age: '12-18',
        title: 'Cybersecurity (Intro to Encryption)',
        location: '',
    },
    {
        date: '16th Jul, 2023',
        time: '1:30-4:30PM',
        mode: '',
        age: '6-14',
        title: 'STEM & BUSINESS WORKSHOP',
        location: '8637 Spicewood Springs Rd, Austin, TX 78759',
    },
    {
        date: '23rd Jul, 2023',
        time: '1:30-4:30PM',
        mode: '',
        age: '6-14',
        title: 'STEM & BUSINESS WORKSHOP',
        location: '8637 Spicewood Springs Rd, Austin, TX 78759',
    },
    {
        date: '21st Jul, 2023',
        time: '2-4pm',
        mode: 'Virtual',
        age: '12-18',
        title: 'STEM & BUSINESS WORKSHOP',
        location: '8637 Spicewood Springs Rd, Austin, TX 78759 ',
    },
    {
        date: '30th Jul, 2023',
        time: '1:30-4:30PM',
        mode: '',
        age: '6-14',
        title: 'STEM & BUSINESS WORKSHOP',
        location: '8637 Spicewood Springs Rd, Austin, TX 78759',
    }
];

const EMAILSETTINGS = {
    serviceId: 'service_4p3zbuq',
    templateId: 'template_20d81jq',
    publicKey: '1uJbdllD3QPlSpqUa',
}

export {
    TEAM,
    EVENTS,
    EMAILSETTINGS
}