import Modal from 'react-bootstrap/Modal';

const Menu = ({show, setShow, handleLinkClick, sections}) => {
    return (
        <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Project2Provide</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='flex-col menu-container'>
            <a className='nav-links' onClick={(e) => handleLinkClick(e, sections[''], '')} href='/'>Home</a>
            <a className='nav-links' onClick={(e) => handleLinkClick(e, sections['about-us'], 'about-us')} href='about-us'>About Us</a>
            <a className='nav-links' onClick={(e) => handleLinkClick(e, sections['our-team'], 'our-team')} href='our-team'>Our Team</a>
            <a className='nav-links' onClick={(e) => handleLinkClick(e, sections['events'], 'events')} href='events'>Events</a>
            <a className='nav-links' onClick={(e) => handleLinkClick(e, sections['contact-us'], 'contact-us')} href='contact-us'>Contact Us</a>
          </div>
        </Modal.Body>
      </Modal>
    )
};

export default Menu;