import '../css/contact.css';
import emaljs from '@emailjs/browser';
import { useForm } from "react-hook-form"
import { FiMail } from 'react-icons/fi';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { EMAILSETTINGS } from '../constants';
import { useState } from 'react';

const ContactForm = () => {
    const [isSubmitted, setSubmitted] = useState(false);
    const [isUserTyping, setUserTyping] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm();
  
    const onSubmit = (data) => {
      setUserTyping(false);
      const { serviceId, templateId, publicKey } = EMAILSETTINGS;
      emaljs.send(serviceId, templateId, data, publicKey);
      setSubmitted(true);
    };
    const handleInputFocus = () => {
      setUserTyping(true);
    };
    console.log(isSubmitted, !isUserTyping, Object.keys(errors).length === 0)
    return (
      <form className='flex-col flex-center' onSubmit={handleSubmit(onSubmit)}>
        {isSubmitted && !isUserTyping && Object.keys(errors).length === 0 && <div className='success'>
          <BsFillCheckCircleFill size={25} color='#73b64f' />
          Thank you for contacting us! We will be in touch with you shortly.
        </div>}
        <input
          className='name'
          onFocus={handleInputFocus}
          placeholder='Name'
          {...register("name", {
            required: "Name is required",
            pattern: {
              value: /^[a-zA-Z ]*$/,
              message: "Name should contain only alphabets and spaces",
            }
          })
        } />
        {errors.name && <div className='error-text'>{errors.name.message}</div>}
        <input 
          className='name'
          placeholder='Email'
          onFocus={handleInputFocus}
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/,
              message: "Please enter valid email",
            }
          })} />
        {errors.email && <div className='error-text'>{errors.email.message}</div>}

        <div className='flx-row-fixed flex-space-between'>
          <div>
            <input
              className='name'
              onFocus={handleInputFocus}
              placeholder='Age'
              {...register("age", {
                required: "Age is required",
              })}
            />
          {errors.age && <div className='error-text text-align-start'>{errors.age.message}</div>}
          </div>
          <div>
            <input
              className='name'
              placeholder='Grade'
              onFocus={handleInputFocus}
              {...register("grade", {
                required: "Grade is required",
              })}
            />
          {errors.grade && <div className='error-text text-align-start'>{errors.grade.message}</div>}
          </div>
        </div>
        <input 
          className='name'
          placeholder='School Name'
          onFocus={handleInputFocus}
          {...register("school", {
            required: "School Name is required",
          })} />
        {errors.school && <div className='error-text'>{errors.school.message}</div>}
        <input
          className='name'
          placeholder='Phone Number'
          onFocus={handleInputFocus}
          {...register("phone", {
            required: "Phone Number is required",
            pattern: {
              value: /^(\([0-9]{3}\)|[0-9]{3})[-. ]?[0-9]{3}[-. ]?[0-9]{4}$/,
              message: "Please enter valid phone number",
            }
          })} />
        {errors.phone && <div className='error-text'>{errors.phone.message}</div>}
        <textarea
          rows="4"
          cols="50"
          className='name'
          placeholder='Message'
          onFocus={handleInputFocus}
          {...register("message", {
            required: "Message is required",
          })} />
        {errors.message && <div className='error-text'>{errors.message.message}</div>}
        <input
            name="submit"
            className="btn"
            type="submit"
            value="Send"
        />
      </form>
    );
  };
  
const Contact = () => {
  return (
    <div className='contact-container flex-col flex-center gradient-bg2'>
        <div className='flx-row flex-space-around scetion-container max-width-1200'>
            <div className='contact flex-col text-align-start width-50'>
                <h1 className='title'>Contact Us</h1>
                <p className='banner-description'>We value your input and welcome your ideas for future workshops. Feel free to share your suggestions using the form below. </p>
                <div className='flx-row-fixed'>
                  <FiMail size={30} />
                  <div>p2pimpact@gmail.com</div>
                </div>
                {/* <div className='flx-row'>
                  <FiPhoneCall size={30}/>
                  <div>(123) 445-7678</div>
                </div>
                <div className='flx-row'>
                  <MdOutlineLocationOn size={30} />
                  <div>1234, Sample street, Austin, Texas</div>
                </div> */}
            </div>
            <div>
              <ContactForm />
            </div>
        </div>
    </div>
  );
}

export default Contact;
