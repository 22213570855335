import { HiMenu } from 'react-icons/hi';

const Header = ({headerColor, handleLinkClick, sections, setShow}) => {
    return (
        <header className="header">
        <div className={`header-home flex-col flex-center ${headerColor === 'scroll' && 'gradient-bg'}`}>
            <div className='max-width-1200 width-100'>
              <div className='flx-row nav-container'>
                <a className='nav-links hide-small' onClick={(e) => handleLinkClick(e, sections[''], '')} href='/'>Home</a>
                <a className='nav-links hide-small' onClick={(e) => handleLinkClick(e, sections['about-us'], 'about-us')} href='about-us'>About Us</a>
                <a className='nav-links hide-small' onClick={(e) => handleLinkClick(e, sections['our-team'], 'our-team')} href='our-team'>Our Team</a>
                <a className='nav-links hide-small' onClick={(e) => handleLinkClick(e, sections['events'], 'events')} href='events'>Events</a>
                <a className='nav-links hide-small' onClick={(e) => handleLinkClick(e, sections['contact-us'], 'contact-us')} href='contact-us'>Contact Us</a>
                <div className='nav-links menu-icon'><HiMenu onClick={() =>  setShow(true)} size={30} /></div>
              </div>
              {headerColor !== 'scroll' && <hr className='divider' />}
            </div>
          </div>
      </header>
    )
}

export default Header;