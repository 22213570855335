import './App.css';
import {useEffect, useState, useRef} from 'react';
import Home from './sections/Home';
import AboutUs from './sections/about';
import Team from './sections/team';
import Events from './sections/events';
import Contact from './sections/contact';
import Footer from './sections/footer';
import Header from './sections/header';
import Menu from './sections/menu';

const App = () => {
  const [show, setShow] = useState(false);
  const homeRef = useRef();
  const aboutRef = useRef();
  const teamRef = useRef();
  const eventsRef = useRef();
  const contactRef = useRef();
  const sections = {
    '': homeRef,
    'about-us': aboutRef,
    'our-team': teamRef,
    'events': eventsRef,
    'contact-us': contactRef,
  }

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleLinkClick = (event, ref, section) => {
    event.preventDefault();
    scrollToSection(ref);
    window.history.pushState(null, null, `/${section}`);
    setShow(false);
  };

  const [headerColor, setHeaderColor] = useState("white")


const listenScrollEvent = () => {
    window.scrollY > 10
      ? setHeaderColor("scroll")
      : setHeaderColor("default")
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)
  });
  return (
    <div className="App">
      <Menu show={show} setShow={setShow} handleLinkClick={handleLinkClick} sections={sections} />
      <Header headerColor={headerColor} handleLinkClick={handleLinkClick} setShow={setShow} sections={sections} />
      <div ref={homeRef}>
        <Home handleNavClick={(e, section) => handleLinkClick(e, sections[section], section)}/>
      </div>
      <div ref={aboutRef}>
        <AboutUs />
      </div>
      <div ref={teamRef}>
        <Team />
      </div>
      <div ref={eventsRef}>
        <Events />
      </div>
      <div ref={contactRef}>
        <Contact />
      </div>
      <Footer handleLinkClick={handleLinkClick} sections={sections} />
    </div>
  );
}

export default App;
